import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from './configuration/services/configuration';
@autoinject()
export class StateService {

    private apiBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private config: EnvironmentConfiguration) {
        this.apiBaseUrl = this.config.env.ApiBaseUrl;
    }

    /**
     * 
     * @param token 
     */
    async getState<T>(token: string): Promise<T> {
        const url = `${this.apiBaseUrl}/state/getstate?token=${token}`;
       
        return this.httpClient.fetch(url)
            .then(x => x.json());
    }

    /**
     * Saves an object and returns the saved key (token)
     * @param jObject 
     */
    async saveState<T>(jObject: T): Promise<string> {
        const url = `${this.apiBaseUrl}/state/savestate`;
        const config = {
            method: "POST",
            body: JSON.stringify(jObject),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config)
            .then(x => x.json());
    }

    /**
     * Updates an object and returns the saved key (token)
     * @param jObject 
     */
    async putState<T>(token: string, jObject: T): Promise<string> {
        const url = `${this.apiBaseUrl}/state/putstate?token=${token}`;
        const config = {
            method: "PUT",
            body: JSON.stringify(jObject),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config)
            .then(x => x.json());
    }
}