import { autoinject } from 'aurelia-framework';
import { IconType } from '../enums/icon-type.enum';

import IconMapStart from './../../../../assets/images/svg/icon-map-start.svg';
import IconMapStopp from './../../../../assets/images/svg/icon-map-stopp.svg';
import IconNotering from './../../../../assets/images/svg/icon-notering.svg';
import IconViaError from './../../../../assets/images/svg/icon-via-error.svg';
import IconMapDbDbTempMarker from './../../../../assets/images/svg/icon-map-db-dbtempmarker.svg';
import IconMapDd1F6575 from './../../../../assets/images/svg/icon-map-db-1f6575.svg';
import IconVia1 from './../../../../assets/images/svg/icon-via-1.svg';
import IconVia2 from './../../../../assets/images/svg/icon-via-2.svg';
import IconVia3 from './../../../../assets/images/svg/icon-via-3.svg';
import IconVia4 from './../../../../assets/images/svg/icon-via-4.svg';
import IconVia5 from './../../../../assets/images/svg/icon-via-5.svg';
import IconVia6 from './../../../../assets/images/svg/icon-via-6.svg';
import IconVia7 from './../../../../assets/images/svg/icon-via-7.svg';
import IconVia8 from './../../../../assets/images/svg/icon-via-8.svg';
import IconVia9 from './../../../../assets/images/svg/icon-via-9.svg';
import IconKoppladPlats from './../../../../assets/images/svg/icon-kopplad-plats.svg';
import IconStorageSite1 from './../../../../assets/images/svg/icon-storage-site-1.svg';
import IconStorageSite2 from './../../../../assets/images/svg/icon-storage-site-2.svg';
import IconStorageSite3 from './../../../../assets/images/svg/icon-storage-site-3.svg';
import IconStorageSite4 from './../../../../assets/images/svg/icon-storage-site-4.svg';
import IconSite1 from './../../../../assets/images/svg/icon-site-1.svg';
import IconSite2 from './../../../../assets/images/svg/icon-site-2.svg';
import IconSite3 from './../../../../assets/images/svg/icon-site-3.svg';
import IconSite4 from './../../../../assets/images/svg/icon-site-4.svg';
import IconLinjalAktiv2 from './../../../../assets/images/svg/icon-linjal-aktiv2.svg';
import IconLinjalInaktiv2 from './../../../../assets/images/svg/icon-linjal-inaktiv2.svg';


@autoinject()
export class IconService {
    public getIcon(icon: IconType): string {
        switch (icon) {
            case IconType.IconMapStart:
                return IconMapStart;
            case IconType.IconMapStopp:
                return IconMapStopp;
            case IconType.IconNotering:
                return IconNotering;
            case IconType.IconViaError:
                return IconViaError;
            case IconType.IconMapDbDbTempMarker:
                return IconMapDbDbTempMarker;
            case IconType.IconMapDd1F6575:
                return IconMapDd1F6575;
            case IconType.IconVia1:
                return IconVia1;
            case IconType.IconVia2:
                return IconVia2;
            case IconType.IconVia3:
                return IconVia3;
            case IconType.IconVia4:
                return IconVia4;
            case IconType.IconVia5:
                return IconVia5;
            case IconType.IconVia6:
                return IconVia6;
            case IconType.IconVia7:
                return IconVia7;
            case IconType.IconVia8:
                return IconVia8;
            case IconType.IconVia9:
                return IconVia9;
            case IconType.IconKoppladPlats:
                return IconKoppladPlats;
            case IconType.IconStorageSite1:
                return IconStorageSite1;
            case IconType.IconStorageSite2:
                return IconStorageSite2;
            case IconType.IconStorageSite3:
                return IconStorageSite3;
            case IconType.IconStorageSite4:
                return IconStorageSite4;
            case IconType.IconSite1:
                return IconSite1;
            case IconType.IconSite2:
                return IconSite2;
            case IconType.IconSite3:
                return IconSite3;
            case IconType.IconSite4:
                return IconSite4;
            case IconType.IconLinjalAktiv2:
                return IconLinjalAktiv2;
            case IconType.IconLinjalInaktiv2:
                return IconLinjalInaktiv2;
        }
    }

    public getIconViaMap(): Map<number, string> {
        const icons = [IconType.IconVia1, IconType.IconVia2, IconType.IconVia3, IconType.IconVia4, IconType.IconVia5, IconType.IconVia6, IconType.IconVia7, IconType.IconVia8, IconType.IconVia9];
        const map = new Map<number, string>();
        for (let i = 0; i < icons.length; i++) {
            map.set(i, this.getIcon(icons[i]));
        }

        return map;
    }

    public getIconStorageSiteMap(): Map<number, string> {
        const icons = [IconType.IconStorageSite1, IconType.IconStorageSite2, IconType.IconStorageSite3, IconType.IconStorageSite4];
        const map = new Map<number, string>();
        for (let i = 0; i < icons.length; i++) {
            map.set(i, this.getIcon(icons[i]));
        }

        return map;
    }

    public getIconSiteMap(): Map<number, string> {
        const icons = [IconType.IconSite1, IconType.IconSite2, IconType.IconSite3, IconType.IconSite4];
        const map = new Map<number, string>();
        for (let i = 0; i < icons.length; i++) {
            map.set(i, this.getIcon(icons[i]));
        }

        return map;
    }
}


