import { autoinject } from "aurelia-framework";
import { NavigationInstruction, Redirect } from 'aurelia-router';
import { AppAuthService } from "services/authentication/app-auth-service";

@autoinject()
export class AuthorizeStep {

	constructor(private authService: AppAuthService) {

	}

	async run(routingContext: NavigationInstruction, next) {
		
		// Ensure roles are fetched from backend
		await this.authService.getPrincipal().initRoles();

		// Ensure map token is fetched from backend
		await this.authService.getPrincipal().initMapToken();

		for (const instruction of routingContext.getAllInstructions()) {

			if (instruction.config.settings.requireLogin && instruction.config.settings.roles) {
				const principal = this.authService.getPrincipal();
				const isAuth = instruction.config.settings.roles.some(x => principal.isInRole(x));

				if (!isAuth) {
					// Redirecting to root -> User is not in any of the configured roles
					return next.cancel(new Redirect(''));
				}
			}
		}

		return next();
	}
}