import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';

import * as L from 'leaflet';

import { ApplicationRepository } from '../application-repository/application-repository';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { SearchType } from 'services/place-search/models/search-type.enum';
import { IMapRoutePoint } from '../application-repository/models/map-route-point.interface';
import { IPhenomena } from './models/phenomena.interface';
import { IDynamicData } from './models/dynamic-data.interface';
import { IDynamicBlock } from './models/blocks/dynamic-block.interface';
import { IFacitRouteContainer } from './models/facit-route-container.interface';
import { IFacitRoute } from './models/facit-route.interface';
import { ILayerGroup } from './models/layer-group.interface';
import { IFeatureTypeInfo } from './models/features/feature-type-info.interface';
import { Site } from './models/site.model';
import { IFeature } from './models/features/feature.interface';


@autoinject()
export class DataLayerService {

	private apiBaseUrl: string;
	private blockagesLayerGroupName: string;
	private siteCache: Map<number, IMapRoutePoint[]>;
	private facitsLayerGroupName: string;
	private dynamicsLayerGroupName: string;

	constructor(
		private applicationRepo: ApplicationRepository,
		private httpClient: HttpClient,
		private config: EnvironmentConfiguration) {
		
		this.apiBaseUrl = this.config.env.ApiBaseUrl;
		const blockagesConfig = this.config.env.Blockages;
		this.blockagesLayerGroupName = blockagesConfig.LayerGroup;
		// const facitsConfig = this.config.env.Blockages;
		this.facitsLayerGroupName = blockagesConfig.LayerGroup;
		const dynamicsConfig = this.config.env.DynamicData;
		this.dynamicsLayerGroupName = dynamicsConfig.LayerGroup;
		this.siteCache = new Map<number, IMapRoutePoint[]>(); 
	}

	public async getSites(siteType: number, crs: L.Proj.CRS): Promise<IMapRoutePoint[]> {
		if (!this.siteCache.has(siteType)) {
			const url = `${this.apiBaseUrl}/Sites/GetSites?siteType=${siteType}`;

			await this.httpClient.fetch(url)
				.then(response => response.json())
				.then((data: Site[] ) => {
					const fixed = [];
					data.forEach(e => {
						const point: L.Point = { x: Number(e.Easting), y: Number(e.Northing) } as L.Point;
						const currentLatLgn = crs.unproject(point);
						const site: IMapRoutePoint = { 
							id: e.Id,
							name: e.Name,
							type: SearchType.SITE,
							easting: Number(e.Easting),
							northing: Number(e.Northing),
							latlng: currentLatLgn,
							projected: point,
							isFavorite: undefined,
							siteType: siteType
						}
						fixed.push(site);
					});

					this.siteCache.set(siteType, fixed);
				}
			);
		}

		return this.siteCache.get(siteType);
	}

	public async getDataLayers(): Promise<ILayerGroup[]> {

		const url = `${this.apiBaseUrl}/DataLayers/ListLayers`;

		return this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: ILayerGroup[] ) => {
				const types = [];
				data.forEach(element => {

					if (element.Name === this.blockagesLayerGroupName) {
						element.HasBlockages = true;
					}
					if (element.Name === this.facitsLayerGroupName){
						element.HasFacits = true;
					}
					if (element.Name === this.dynamicsLayerGroupName){
						element.HasDynamics = true;
					}

					element.DataLayers.forEach(item => {
						const type: IFeatureTypeInfo = {
							FeatureTypeName: item.Name,
							LayerName: item.OwsLayerName
						} 
						types.push(type);
					});
				});

				this.applicationRepo.setFeatureTypes(types);

				return data;
			});
	}

	public async getPhenomenon(): Promise<IPhenomena[]>{
		const url = `${this.apiBaseUrl}/DataLayers/GetPhenomenon`;
		
		return this.httpClient.fetch(url)
			.then(x => x.json())
			.then(x => x.Items);
	}

	public async getFeaturesAtLocation(
        x: number, 
        y: number, 
        layer: string, 
        viewDate: string,
        bufferMeters: number): Promise<IFeature[]> {
        
            const url = `${this.apiBaseUrl}/DataLayers/GetFeaturesAtLocation?x=${x}&y=${y}&layers=${layer}&viewDate=${viewDate}&toleranceMeters=${bufferMeters}`;

		return this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: IFeature[]) => {
				return data;
			});
	}

	public async getIntersectingFeatures(
		left: number, 
		bottom: number, 
		right: number, 
		top: number, 
        layer: string,
        viewDate: string): Promise<IFeature[]> {

		const url = `${this.apiBaseUrl}/DataLayers/GetIntersectingFeatures?left=${left}&bottom=${bottom}&right=${right}&top=${top}&layers=${layer}&viewDate=${viewDate}`;

		return this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: IFeature[]) => {
				return data;
			});
	}

	public async getTpFeatures(siteId: string): Promise<IFeature[]> {
		const url = `${this.apiBaseUrl}/DataLayers/GetTpFeatures?siteId=${siteId}`;

		return this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: IFeature[]) => {
				return data;
			});
	}

	public async getDynamicBlocks(): Promise<IDynamicBlock[]> {
		const url = `${this.apiBaseUrl}/DataLayers/GetDynamicBlocks`;

		return await this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: IDynamicBlock[]) => {
				return data;
			});
	}

	public async getFacitRoutes(): Promise<IFacitRouteContainer[]>{
		const url = `${this.apiBaseUrl}/DataLayers/GetFacitRoutes`;
		const facits: IFacitRouteContainer[] = [];

		return await this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: IFacitRoute[]) => {
				data.forEach(d => {
					facits.push({
						facit:d,
						id: d.FacitId,
						haloLayer: null,
						layer: null,
						selected: false
					});
				});
				return facits;
			});
	}

	public async getDynamicData(): Promise<IDynamicData[]>{
		const url = `${this.apiBaseUrl}/DataLayers/GetDynamicData`;

		return await this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: IDynamicData[]) => {
				return data;
			});
	}

	public getLegendGraphicUrl(layer: string, token: string): string {
		return `${this.apiBaseUrl}/WMS/DataLayer?service=WMS&request=GetLegendGraphic&layer=${layer}_legend&style=&format=image%2Fpng&VERSION=1.1.1&token=${token}`;
	}

	public expandBounds(bounds: L.LatLngBounds, factor: number): L.LatLngBounds {
		const southWest = bounds.getSouthWest();
		const northEast = bounds.getNorthEast();

		const dX = (northEast.lng - southWest.lng) * factor;
		const dY = (northEast.lat - southWest.lat) * factor;

		southWest.lng -= dX / 2;
		southWest.lat -= dY / 2;

		northEast.lng += dX / 2;
		northEast.lat += dY / 2;

		const newBounds: L.LatLngBounds = new L.LatLngBounds(southWest, northEast);
		return newBounds;
	}
}
































