import { autoinject, inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { OAuthService, OAuthTokenService } from 'aurelia-oauth';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { AppRole } from 'services/authentication/models/app-roles.enum';
import { IPrincipal } from './models/principal.interface';

/** 
 * Class that wraps aurelia-oauth and adds some app specific role logic
*/
@autoinject()
export class AppAuthService {

	private userPrincipal: IPrincipal;
	private apiBaseUrl: string;

	constructor(
		private config: EnvironmentConfiguration,
		private httpClient: HttpClient,
		private oauthService: OAuthService,
		private oauthTokenService: OAuthTokenService) {
		this.apiBaseUrl = this.config.env.ApiBaseUrl;

		// If we are authenticated set the principal
		if (this.isAuthenticated()) {
			this.claimsToPrincipal();
		}
	}
	
	public login(): void {
		this.userPrincipal = undefined;

		return this.oauthService.login();
	}

	public logout(): void {
		this.userPrincipal = undefined;

		return this.oauthService.logout();
	}
	
	public getToken(): any {
		return this.oauthTokenService.getToken();
	}

	public isAuthenticated(): boolean {
		return this.oauthService.isAuthenticated();
	}
	
	public getPrincipal(): IPrincipal {
		return this.userPrincipal;
	}

	private claimsToPrincipal(): void {
		const token = this.oauthTokenService.getToken();
		if (!token) {
			this.userPrincipal = undefined;
			return;
		}

		const claims = this.getTokenPayload(token.token);

		if (!claims) {
			this.userPrincipal = undefined;
			return;
		}

		const getMapToken = async(): Promise<string> => {
			const url = `${this.apiBaseUrl}/Authentication/MapToken`;
		
			const response = await this.httpClient.fetch(url);
			const token = await response.json();
			
			return token;
		}

		const getRoles = async(): Promise<AppRole[]> => {	
			const url = `${this.apiBaseUrl}/Authentication/Roles`;
			const response = await this.httpClient.fetch(url);
			const roles = await response.json();
			return roles;
		}
		
		const isInRole = (principal: IPrincipal, role: AppRole): boolean => {

			if (!principal.roles) {
				return false;
			}
			
			const matchedRole = principal.roles.find(x => x === role);
			if (matchedRole) {
				return true;
			}
	
			return false;
		}

		const someRoles = (principal: IPrincipal, roles: AppRole[]): boolean => {
			if (!principal.roles) {
				return false;
			}

			return roles.some(role => {
				const matchedRole = principal.roles.find(x => x === role);
				if (matchedRole) {
					return true;
				}
		
				return false;
			});

		}
		
		const principal: IPrincipal = {
			email: claims.email,
			name: claims.name,
			userName: claims.name ? claims.name : claims.unique_name,
			roles: undefined,
			initMapToken: async() => {
				if (!(principal as any)._mapTokenInitialized) {
					const token = await getMapToken();
					principal.mapToken = token;
					(principal as any)._mapTokenInitialized = true;
				}
			},
			initRoles: async() => {
				if (!(principal as any)._initialized) {
					const roles = await getRoles();
					principal.roles = roles;
					(principal as any)._initialized = true;
				}
			},
			isInRole: (role: AppRole) => {
				if (!(principal as any)._initialized) {
					throw new Error("The principal is not initialized. Please call 'initRoles'.");
				}
				return isInRole(principal, role);
			},
			someRoles: (roles: AppRole[]) =>{
				if (!(principal as any)._initialized) {
					throw new Error("The principal is not initialized. Please call 'initRoles'.");
				}
				return someRoles(principal, roles);
			}
		}

		this.userPrincipal = principal;
	}

	private getTokenPayload(token: string): ICustomJwtClaims {
		const jwtTokenPartsRegex = /^([^\.\s]*)\.([^\.\s]+)\.([^\.\s]*)$/;
		const matches = jwtTokenPartsRegex.exec(token);
		const isValidToken = matches && matches.length === 4;

		const payload = isValidToken ? matches[2] : '';

		const json = decodeURIComponent(atob(payload).replace(/(.)/g, (m, p) => {
			let code = p.charCodeAt(0).toString(16).toUpperCase();
			
			if (code.length < 2) {
				code = '0' + code;
			}

			return '%' + code;
		}));

		return JSON.parse(json);
	}
}



