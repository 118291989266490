import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { SearchType } from 'services/place-search/models/search-type.enum';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { IPlaceSearchItem } from './models/place-search-item.interface';

@autoinject()
export class PlaceSearchService {

	private apiBaseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private config: EnvironmentConfiguration) {
		this.apiBaseUrl = this.config.env.ApiBaseUrl;
	}

	public getSuggestions(search: string, types: SearchType[] = null): Promise<IPlaceSearchItem[]> {
		let typesUri = '';

		if (types !== null) {
			types.forEach(type => {
				typesUri += '&types=' + type;
			});
		}
		
		const toggleConfig = this.config.get('FeatureToggle');
		const url = `${this.apiBaseUrl}/Search/Suggest?s=${search + typesUri}&isViol3=${toggleConfig.IsViol3}`;

		return this.httpClient.fetch(url)
			.then(response => response.json())
			.then((data: PlaceSearchItemResponse[]) => {
				return data.map(x => {
					const ret: IPlaceSearchItem = {
						id: x.Id,
						name: x.Name,
						type: x.Type as SearchType,
						easting: x.Easting,
						northing: x.Northing,
						sortOrder: x.SortOrder,
						geometry: this.formatPlaceSearchItemGeometry(x.Geometry),
						siteType: x.SiteType
					};
					return ret;
				}
			)});
	}

	public formatItem(searchItem: IPlaceSearchItem): string {
		return searchItem.name;
	}

	public sortPlaceSearchItemsByName(array: IPlaceSearchItem[]): IPlaceSearchItem[] {
		if (!array) {
			return array;
		}

		const factor = 'ascending'.match(/^desc*/i) ? 1 : -1;
		const retvalue = array.sort((a, b) => {
			const textA = a.name.toUpperCase();
			const textB = b.name.toUpperCase();
			return (textA < textB) ? factor : (textA > textB) ? -factor : 0;
		});

		return retvalue;
	}

	public sortPlaceSearchItemsByFavoriteAndName(array: IPlaceSearchItem[]): IPlaceSearchItem[] {
		if (!array) {
			return array;
		}

		const factor = 'ascending'.match(/^desc*/i) ? 1 : -1;
		const retvalue = array.sort((a, b) => {
			if (a.isFavorite === b.isFavorite) {
				if (a.sortOrder && b.sortOrder){
					return (a.sortOrder < b.sortOrder) ? factor : (a.sortOrder > b.sortOrder) ? -factor : 0;
				}

				const textA = a.name.toUpperCase();
				const textB = b.name.toUpperCase();
				return (textA < textB) ? factor : (textA > textB) ? -factor : 0;
			}

			return a.isFavorite ? -1 : 1;
		});

		return retvalue;
	}

	private formatPlaceSearchItemGeometry(source: { X: number, Y: number }[]): { x: number, y: number }[] {
		if(!source)	 {
			return null;
		}

		return source.map(x => {
			return { x: x.X, y: x.Y }
		});
	}
}