import { autoinject } from 'aurelia-framework';
import * as L from 'leaflet';
import 'proj4leaflet';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { ExtendedPoint } from './models/extended-point.interface';

@autoinject()
export class MapConfiguration {
    
    private dateNow: string;
    private wholeZoomLevel = 0;
    private wholeCenter: L.LatLng =  new L.LatLng(62.613562, 14.545898);
    private resolutions = [2048, 1536, 1024, 768, 512, 384, 256, 192, 128, 96, 64, 48, 32, 16, 8, 4, 2, 1, 0.5]; // 3 example zoom level resolutions
    private crs: L.Proj.CRS; 

    constructor(
        private config: EnvironmentConfiguration
    ) {
        const now: Date = new Date();
        const paddedMonth = ("0" + (now.getMonth() + 1)).slice(-2)
        const paddedDate = ("0" + now.getDate()).slice(-2);

        this.dateNow = `${now.getFullYear()}${paddedMonth}${paddedDate}`;
        this.crs = new L.Proj.CRS('EPSG:3006', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs', {
            origin: [218128.7031, 6126002.9379],
            resolutions: this.resolutions
        });
    }

    /**
     * Returns the base layers for the map
     */
    public getBaseLayers(): any {
        // Subdomains can be null (dev settings)
        const mapSubDomains: number[] = this.config.env.MapSubDomains;
        const wmsBaseUrl: string = this.config.env.WmsBaseUrl;

        return {
            base: [
                {
                    id: 'Bakgrundskarta',
                        options: {
                            layers: 'MetriaTatortPlus_SDC',
                            format: 'image/png',
                            TRANSPARENT: 'TRUE',
                            FORMAT_OPTIONS: L.Browser.retina ? 'dpi:192' : '',
                            detectRetina: true,
                            subdomains: mapSubDomains,
                            tileSize: 512
                        },
                        type: 'wms',
                        url: wmsBaseUrl + "/BackgroundMap"
                }
            ],
            overlay: [
                {
                        id: 'NVDB Vägar',
                        options: {
                            layers: 'TNE_REFLINKPART,LAYER_20_1_UI,LAYER_4_1_UI',
                            format: 'image/png',
                            TRANSPARENT: 'TRUE',
                            FORMAT_OPTIONS: L.Browser.retina ? 'dpi:192' : '',
                            detectRetina: true,
                            P_SEARCHDATE: this.dateNow,
                            minZoom: 12,
                            subdomains: mapSubDomains,
                            tileSize: 512
                        },
                        type: 'wms',
                        url:  wmsBaseUrl + "/DataLayer"
                }
            ]
        };
    }

    /**
     * Returns map-options for the Map
     * @param map 
     * @param routeFromUpdated 
     * @param routeToUpdated 
     * @param routeViaUpdated 
     */
    public getMapOptions(
        map: () => L.Map,
        routeFromUpdated: (newPos: ExtendedPoint) => void,
        routeToUpdated: (newPos: ExtendedPoint) => void,
        routeViaUpdated: (newPos: ExtendedPoint) => void): any {

        return {
            contextmenu: true,
            contextmenuWidth: 200,
            contextmenuItems: [
                {
                    text: '<i class="fa fa-location-arrow" aria-hidden="true"></i>'
                },
                {
                    text: '<i class="fa fa-clipboard" aria-hidden="true"></i>Kopiera koordinat till urklipp',
                    callback: (e: { latlng: L.LatLng }): void => {
                          const point = this.crs.project(e.latlng);
                          navigator.clipboard.writeText(point.x.toFixed(0) + ', ' + point.y.toFixed(0));
                    }
                },
                {
                    text: '<i class="fa fa-arrows" aria-hidden="true"></i>Centrera kartan här',
                    callback: (e: { latlng: L.LatLng }): void => { map().panTo(e.latlng); }
                },
                {
                    text: '<i class="fa fa-sticky-note-o" aria-hidden="true"></i>Notering'
                },
                '-',
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-start"><span class="path1"></span></span>Rutt härifrån',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        routeFromUpdated({ latlng: e.latlng, projected: location });
                    }
                }, 
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-via"><span class="path1"></span><span class="path2"></span></span>Rutt via',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        routeViaUpdated({ latlng: e.latlng, projected: location });
                    }
                },
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-stopp"><span class="path1"></span><span class="path2"></span></span>Rutt hit',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        routeToUpdated({ latlng: e.latlng, projected: location });
                    }
                },
                '-', {
                    text: '<i class="fa fa-search-plus" aria-hidden="true"></i>Zooma in',
                    callback: (e: { latlng: L.LatLng }): void => { map().zoomIn(); }
                }, {
                    text: '<i class="fa fa-search-minus" aria-hidden="true"></i>Zooma ut',
                    callback: (e: { latlng: L.LatLng }): void => { map().zoomOut(); }
                }],
            positionControl: {} // Slår på visning av Mus-position
            ,
            center: this.wholeCenter,
            zoomLevel: this.wholeZoomLevel,
            maxZoom: 18,
            crs: this.crs,
            continuousWorld: true,
            worldCopyJump: false,
            token: null,
            zoomControl: false
        };
    }

    /**
     * Returns map-options for the Admin Map
     * @param map 
     * @param routeFromUpdated 
     * @param routeToUpdated 
     * @param routeViaUpdated
     * @param dynamicBlockCoordUpdated 
     * @param dynamicBlockPolygonUpdated
     */
    public getAdminMapOptions (
        map: () => L.Map,
        routeFromUpdated: (newPos: ExtendedPoint) => void,
        routeToUpdated: (newPos: ExtendedPoint) => void,
        routeViaUpdated: (newPos: ExtendedPoint) => void,
        dynamicBlockCoordUpdated:(newPos:ExtendedPoint) => void,
        dynamicBlockPolygonUpdated:() => void): any {

        return {
            contextmenu: true,
            contextmenuWidth: 200,
            contextmenuItems: [
                {
                    text: '<i class="fa fa-location-arrow" aria-hidden="true"></i>'
                },
                {
                    text: '<i class="fa fa-clipboard" aria-hidden="true"></i>Kopiera koordinat till urklipp',
                    callback: (e: { latlng: L.LatLng }): void => {
                          const point = this.crs.project(e.latlng);
                          navigator.clipboard.writeText(point.x.toFixed(0) + ', ' + point.y.toFixed(0));
                    }
                },
                {
                    text: '<i class="fa fa-arrows" aria-hidden="true"></i>Centrera kartan här',
                    callback: (e: { latlng: L.LatLng }): void => { map().panTo(e.latlng); }
                },
                '-',
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-start"><span class="path1"></span></span>Rutt härifrån',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        routeFromUpdated({ latlng: e.latlng, projected: location });
                    }
                }, 
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-via"><span class="path1"></span><span class="path2"></span></span>Rutt via',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        routeViaUpdated({ latlng: e.latlng, projected: location });
                    }
                },
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-stopp"><span class="path1"></span><span class="path2"></span></span>Rutt hit',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        routeToUpdated({ latlng: e.latlng, projected: location });
                    }
                },
                '-',
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-db-1f6575" style="color:#1f6575 !important"><span class="path1"></span><span class="path2"></span></span>Dynamicblock här',
                    callback: (e: { latlng: L.LatLng }): void => {
                        const location = this.crs.project(e.latlng);
                        dynamicBlockCoordUpdated({ latlng: e.latlng, projected: location });
                    }
                },
                {
                    text: '<span style="font-size:16px; padding-right:10px;" class="fa fa-hexagon" style="color:#1f6575 !important"><span class="path1"></span><span class="path2"></span></span>Rita dynamicblock',
                    callback: (e: { latlng: L.LatLng }): void => {
                        dynamicBlockPolygonUpdated();
                    }
                },
                '-', {
                    text: '<i class="fa fa-search-plus" aria-hidden="true"></i>Zooma in',
                    callback: (e: { latlng: L.LatLng }): void => { map().zoomIn(); }
                }, {
                    text: '<i class="fa fa-search-minus" aria-hidden="true"></i>Zooma ut',
                    callback: (e: { latlng: L.LatLng }): void => { map().zoomOut(); }
                }],
            positionControl: {} // Slår på visning av Mus-position
            ,
            center: this.wholeCenter,
            zoomLevel: this.wholeZoomLevel,
            maxZoom: 18,
            crs: this.crs,
            continuousWorld: true,
            worldCopyJump: false,
            token: null,
            zoomControl: false
        };
    }

    /**
     * Returns resolutions configured for map
     */
    public getResolutions(): number[] {
        return this.resolutions
    }

    /**
     * Returns scales for configured resolutions
     */
    public getScales(): number[] {
        const scales = this.getResolutions().map(x => this.getScaleFromResolution(x));
        return scales;
    }

    public getMapCrs(): {crs: L.Proj.CRS} {
        return {
            crs: this.crs
        };
    }

    /**
    * Function: getScaleFromResolution
    * 
    * Parameters:
    * resolution - {Float}
    * units - {String} Index into OpenLayers.INCHES_PER_UNIT hashtable.
    *                  Default is degrees
    * 
    * Returns:
    * {Float} The corresponding scale given passed-in resolution and unit 
    *         parameters.
    */
    private getScaleFromResolution (resolution) {
        const inchesPerMeter = 39.37;
        const dotsPerInch = 72;
        const scale = resolution * inchesPerMeter * dotsPerInch;

        return scale;
    }
    
}