export enum SearchType {
	SITE = 'Site',
	ROAD = 'Road',
	PLACE = 'Place',
	COORDINATE = 'Coordinate',
	TPLED = 'TP-Led',
	TIMMERLED = 'Timmerled',
	REKOMMENDERADLED = 'Rekommenderadled',
	FACITRUTT  = 'Facitrutt',
	FAVORITES  = 'Favorites',
	LAST_SEARCHES  = 'LastSearches',
	CATEGORY  = 'Category'
}