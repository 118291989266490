import { EnvironmentInterface } from 'services/configuration/models/environment.interface';
import envBase from '../configurations/environment.json';
import envLocal from '../configurations/environment.localhost.json';
import envViol2 from '../configurations/environment.triona_viol2.json';
import envSit1 from '../configurations/environment.sit1.json';

export class EnvironmentMapper {
    constructor(private hostName: string) {
    }


    public getEnvironment(): EnvironmentInterface {
        
        switch(this.hostName) {
            case 'localhost':
                return this.mergeEnv(envLocal);
            case 'vagval-viol2.triona.se':
                return this.mergeEnv(envViol2);
            // case 'vagval-viol3.triona.se':
            //     return envronmentTriona_viol3;
            case 'vagval.sit1.sdc.se':
            case 'sdc-vagval-sit1.azurewebsites.net':
                return this.mergeEnv(envSit1);



            default:
                throw new Error(`Could not locate config for hostname: ${this.hostName}`)
        }
    }

    private mergeEnv(environment: any) {
        return {...environment, ...envBase};
    }
}