import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { IVehicleDefinition } from './models/vehicle-definition.interface';
import { ICoordinateSystem } from './models/coordinate-system.interface';
import { IResistanceSetting } from './models/resistance-settings.interface';
import { ICalculatedRouteRequest } from './models/calculated-route-request.interface';
import { ICalculatedRoute } from './models/calculated-route.interface';

@autoinject()
export class RoutingService {

	private resistanceSettings: IResistanceSetting[];
	private coordinateSystems: ICoordinateSystem[];
	private vehicleDefinitions: IVehicleDefinition[];

	private apiBaseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private config: EnvironmentConfiguration) {
		this.apiBaseUrl = this.config.env.ApiBaseUrl;
	}

	public async calculateRoute(routeRequest: ICalculatedRouteRequest): Promise<ICalculatedRoute> {
		const url = `${this.apiBaseUrl}/Routing/CalculateRoute`;
        const config = {
            method: "POST",
			body: JSON.stringify(routeRequest),
			headers : {'Content-Type': 'application/json'}
        };

		return this.httpClient.fetch(url, config)
			.then(response => response.json())
			.then((data: any) => {
				return data;
			});
    }
    
    public async createTestRoutingDataLayer (
        left: number, 
		bottom: number, 
		right: number, 
		top: number): Promise<number> {

        const url = `${this.apiBaseUrl}/Routing/CreateTestRoutingDataLayer?xmin=${left}&xmax=${right}&ymin=${bottom}&ymax=${top}`;
        const config = {
            method: "POST",
            body: null,
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config).then(x => x.json());
    }

	public async getResistanceSettings(): Promise<IResistanceSetting[]> {
		if (!this.resistanceSettings) {
			const url = `${this.apiBaseUrl}/Routing/GetRouteServices`;

			await this.httpClient.fetch(url)
				.then(response => response.json())
				.then((data: IResistanceSetting[]) => {
					this.resistanceSettings = data;
				});
		}

		return this.resistanceSettings;
	}

	public async getCoordinateSystems(): Promise<ICoordinateSystem[]> {
		if (!this.coordinateSystems) {
			const url = `${this.apiBaseUrl}/Routing/GetCoordinateSystems`;

			await this.httpClient.fetch(url)
				.then(response => response.json())
				.then((data: ICoordinateSystem[]) => {
					this.coordinateSystems = data;
				});
		}

		return this.coordinateSystems;
	}
	
	public async getVehicleDefinitions(): Promise<IVehicleDefinition[]> {
		if (!this.vehicleDefinitions) {
			const url = `${this.apiBaseUrl}/Routing/GetVehicleDefinitions`;

			await this.httpClient.fetch(url)
				.then(response => response.json())
				.then((data: IVehicleDefinition[]) => {
					this.vehicleDefinitions = data;
				});
		}

		return this.vehicleDefinitions;
	}
}