export enum IconType {
    IconMapStart,
    IconMapStopp,
    IconNotering,
    IconViaError,
    IconMapDbDbTempMarker,
    IconMapDd1F6575,
    IconVia1,
    IconVia2,
    IconVia3,
    IconVia4,
    IconVia5,
    IconVia6,
    IconVia7,
    IconVia8,
    IconVia9,
    IconKoppladPlats,
    IconStorageSite1,
    IconStorageSite2,
    IconStorageSite3,
    IconStorageSite4,
    IconSite1,
    IconSite2,
    IconSite3,
    IconSite4,
    IconLinjalAktiv2,
    IconLinjalInaktiv2,
}