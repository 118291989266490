import { PLATFORM, autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import * as L from 'leaflet';
import { EnvironmentConfiguration } from './configuration/services/configuration';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IconService } from './assets/services/icon.service';
import { IconType } from './assets/enums/icon-type.enum';

@autoinject()
export class CustomMapControllers {

	private isViol3Mode: boolean;

    constructor(
		private eventAggregator: EventAggregator,
        private config: EnvironmentConfiguration,
        private iconService: IconService) {
		this.isViol3Mode = this.config.env.FeatureToggle.IsViol3;
    }

    public addCustomControls(map: L.Map): void {
        const zoomOptions = new L.Control.Zoom;
        zoomOptions.options.zoomInTitle = "Zooma in";
        zoomOptions.options.zoomOutTitle = "Zooma ut";
        map.addControl(zoomOptions);
        this.customZoomControllers(map);
        this.zoomToWorldController(map);
        this.customMeasureController(map);
    }

    private zoomToWorldController(map: L.Map): void {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        const iZoomToWorld = L.DomUtil.create('i', 'fa fa-globe');
        iZoomToWorld.style.fontSize = '25px';
        iZoomToWorld.style.margin = '3px';

        const zoomToWorld = L.DomUtil.create('a', 'leaflet-control-custom');
        zoomToWorld.id = 'zoom0';
        zoomToWorld.title = 'Zooma till full utbredning';

        zoomToWorld.onclick = () => {
            map.setZoom(0);
        };

        zoomToWorld.appendChild(iZoomToWorld);
        container.appendChild(zoomToWorld);
        const ourCustomControl = L.Control.extend({
            options: {
                position: 'topleft'
            },
            onAdd: (map) => {
                return container;
            }
        });
        map.addControl(new ourCustomControl());
    }

    private customZoomControllers(map: L.Map): void {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        container.style.position = 'fixed';
        container.style.bottom = '45px';

        const zoomFeatures = L.DomUtil.create('a', 'leaflet-control-custom');
        const road = L.DomUtil.create('i', 'fa fa-road');
        zoomFeatures.id = 'zoom6';
        zoomFeatures.title = 'Zooma till vägnät och företeelser';
        zoomFeatures.style.backgroundColor = 'white';
        zoomFeatures.style.width = '45px';
        zoomFeatures.style.height = '30px';
        zoomFeatures.style.fontSize = '18px';
        zoomFeatures.onclick = () => {
            map.setZoom(12); // res 32
        };
        zoomFeatures.appendChild(road);
        container.appendChild(zoomFeatures);

        // Controller to see a good orientation view
        const zoomOrientation = L.DomUtil.create('a', 'leaflet-control-custom');
        const mottagningsplats = L.DomUtil.create('span', 'icon-mottagningsplats');
        
        //zoomOrientation.innerText = '<span class="icon-icon-mottagningsplats"></span>';
        zoomOrientation.title = this.isViol3Mode ? 'Zooma till Platser (mätplats, mottagningsplats, hämtplats och omlastningsplats )' : 'Zooma till mottagningsplatser'
        zoomOrientation.style.backgroundColor = 'white';
        zoomOrientation.id = 'zoom3';
        zoomOrientation.style.width = '45px';
        zoomOrientation.style.height = '30px';
        zoomOrientation.style.fontSize = '18px';
        zoomOrientation.onclick = () => {
            map.setZoom(6); // res 256
        };
        zoomOrientation.appendChild(mottagningsplats);
        container.appendChild(zoomOrientation);

        const zoomReceivingLocations = L.DomUtil.create('a', 'leaflet-control-custom');
        const view = L.DomUtil.create('i', 'fa fa-eye');
        zoomReceivingLocations.style.backgroundColor = 'white';
        zoomReceivingLocations.id = 'zoom2';
        zoomReceivingLocations.title = 'Zooma till orienteringsnivå';
        zoomReceivingLocations.style.width = '45px';
        zoomReceivingLocations.style.height = '30px';
        zoomReceivingLocations.style.fontSize = '18px';
        zoomReceivingLocations.onclick = (): void => {
            map.setZoom(4); // res 512
        };
        zoomReceivingLocations.appendChild(view);
        container.appendChild(zoomReceivingLocations);

        
        const ourCustomControl = L.Control.extend({
            options: {
                position: 'bottomleft'
            },
            onAdd: (map) => {
                return container;
            }
        });
        map.addControl(new ourCustomControl());
    }

    private customMeasureController(map: L.Map): void {
        const ctrl = L.control.polylineMeasure(this.getPolyLineMeasureOptions()).addTo(map);

        const options = {
			position: 'topleft' as L.ControlPosition,
			states: [{
				stateName: 'measure-inactive',
				// title: 'Mät i kartan',
				icon: `<img src=${this.iconService.getIcon(IconType.IconLinjalInaktiv2)}>`,
				leafletClasses: true,
				onClick: (btn, map): void => {
                    btn.state('measure-active');
                    this.eventAggregator.publish(MapEventType.MAP_CLICK_ENABLED, false);
                    (ctrl as any)._toggleMeasure();
				}
            },
            {
				stateName: 'measure-active',
				title: 'Avluta mätning',
				icon: `<img src=${this.iconService.getIcon(IconType.IconLinjalAktiv2)}>`,
				leafletClasses: true,
				onClick: (btn, map): void => {
                    btn.state('measure-inactive');
                    this.eventAggregator.publish(MapEventType.MAP_CLICK_ENABLED, true);
                    (ctrl as any)._toggleMeasure();
				}
			}]
		};

		(L as any).easyButton(options).addTo(map);
    }

    private getPolyLineMeasureOptions(): L.Control.PolylineMeasureOptions {
        return {
            position: 'bottomright',                    // Position to show the control. Possible values are: 'topright', 'topleft', 'bottomright', 'bottomleft'
            unit: 'metres',                         // Show imperial or metric distances. Values: 'metres', 'landmiles', 'nauticalmiles'
            //showBearings: false,                    // Whether bearings are displayed within the tooltips
            // measureControlTitleOn: 'Turn on measure tool',   // Title for the control going to be switched on
            // measureControlTitleOff: 'Turn off measure tool', // Title for the control going to be switched off
            // measureControlLabel: '<span style="font-size:30px" class="icon-icon-linjal-inaktiv"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',         // HTML to place inside the control
            measureControlClasses: ['measure-control'],              // Classes to apply to the control
            backgroundColor: '#fff',                // Background color for control when selected
            cursor: 'crosshair',                    // Cursor type to show when creating measurements
            clearMeasurementsOnStop: true,          // Clear all the measurements when the control is unselected
            showMeasurementsClearControl: false,    // Show a control to clear all the measurements
            // clearControlTitle: 'Clear measurements',// Title text to show on the clear measurements control button
            // clearControlLabel: '<span style="font-size:30px" class="icon-icon-linjal-aktiv"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',            // Clear control inner html
            clearControlClasses: [],                // Collection of classes to add to clear control button
            showUnitControl: false,                 // Show a control to change the units of measurements
            tempLine: {                             // Styling settings for the temporary dashed line
                color: '#DDDDDD',                      // Dashed line color
                weight: 2                           // Dashed line weight
            },          
            fixedLine: {                            // Styling for the solid line
                color: '#999999',                      // Solid line color
                weight: 2                           // Solid line weight
            },
            startCircle: {                          // Style settings for circle marker indicating the starting point of the polyline
                color: '#000',                      // Color of the border of the circle
                weight: 1,                          // Weight of the circle
                fillColor: '#1F6575',                  // Fill color of the circle
                fillOpacity: 1,                     // Fill opacity of the circle
                radius: 5                           // Radius of the circle
            },
            intermedCircle: {                       // Style settings for all circle markers between startCircle and endCircle
                color: '#000',                      // Color of the border of the circle
                weight: 1,                          // Weight of the circle
                fillColor: '#EF6B29',                  // Fill color of the circle
                fillOpacity: 1,                     // Fill opacity of the circle
                radius: 3                           // Radius of the circle
            },
            currentCircle: {                        // Style settings for circle marker indicating the latest point of the polyline during drawing a line
                color: '#000',                      // Color of the border of the circle
                weight: 1,                          // Weight of the circle
                fillColor: '#63949F',                  // Fill color of the circle
                fillOpacity: 1,                     // Fill opacity of the circle
                radius: 3                           // Radius of the circle
            },
            endCircle: {                            // Style settings for circle marker indicating the last point of the polyline
                color: '#000',                      // Color of the border of the circle
                weight: 1,                          // Weight of the circle
                fillColor: '#A0316D',                  // Fill color of the circle
                fillOpacity: 1,                     // Fill opacity of the circle
                radius: 5                           // Radius of the circle
            },
        };
    }
}
