import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { Version } from './models/version.model';

@autoinject()
export class VersionsService {
	private apiBaseUrl: string;

	constructor(
		private httpClient: HttpClient,
		private config: EnvironmentConfiguration
	) {

		this.apiBaseUrl = this.config.env.ApiBaseUrl;
	}


	async getVersions(): Promise<Version[]> {

		const url = `${this.apiBaseUrl}/version/GetVersions`;
		return this.httpClient.fetch(url)
			.then(response => response.json());
	}

}