import { autoinject } from "aurelia-framework";
import { EnvironmentInterface } from "../models/environment.interface";
import { EnvironmentMapper } from './environment-mapper';

@autoinject()
export class EnvironmentConfiguration {
    private environment: EnvironmentInterface;
	get: any;
    
    initialize() {
        this.environment = this.environmentMapper();
    }


    private environmentMapper(): EnvironmentInterface {
        const env = new EnvironmentMapper(window.location.hostname);
        return env.getEnvironment();
    }

    get env() {
        return this.environment;
    }

}