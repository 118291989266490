import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from "aurelia-dialog";
import { Router } from 'aurelia-router';
import { MapBase } from "components/common/map-base/map-base";
import { AppAuthService } from "services/authentication/app-auth-service";
import { CustomMapControllers } from "services/custom-map-controllers.service";
import { SearchPopup } from "components/common/popups/search-popup";
import { ApplicationRepository } from "services/application-repository/application-repository";
import { MapConfiguration } from "services/map-configuration/map-configuration";

@autoinject()
export class Admin extends MapBase {

	protected layers;
	protected mapOptions;
	
	constructor(
		eventAggregator: EventAggregator, 
		mapConfig: MapConfiguration,
		appAuthService: AppAuthService,
		dialogService: DialogService,
		customMapControllers: CustomMapControllers, 
		searchPopup: SearchPopup,
		applicationRepo: ApplicationRepository,
		router: Router) {
			super(eventAggregator, mapConfig, appAuthService, dialogService, customMapControllers, searchPopup, applicationRepo, router, true);
			this.layers = mapConfig.getBaseLayers();
			this.mapOptions = mapConfig.getAdminMapOptions(
				() => this.map,
				this.setRouteStartLocation,
				this.setRouteEndLocation,
				this.setRouteViaInMap,
				this.setDynamicBlockCoordInMap,
				this.setDynamicBlockPolygonInMap
			);
			this.applicationRepo.mapCrs = this.mapOptions.crs;
	}
}
