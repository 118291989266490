import { autoinject } from 'aurelia-framework';

import * as L from 'leaflet';
import { EnvironmentConfiguration } from '../configuration/services/configuration';
import { IRouteInformation } from './models/route-information.interface';
import { IFeatureTypeInfo } from 'services/data-layer/models/features/feature-type-info.interface';

@autoinject()
export class ApplicationRepository {
    private _map: L.Map;
    private _mapCrs: L.Proj.CRS;
    private _featureTypes: IFeatureTypeInfo[];
	private _routesInformation: IRouteInformation[] = [];
    private _maxNumViaLocations: number;
    private _routeColors: string[] = ['#1F6575', '#E23F37', '#35962B', '#DE3163', '#F49F25'];
	private _colorsCount = 0;
    private _viewDate: string;

    constructor(
        private config: EnvironmentConfiguration
    ) {
        const routingConfig = this.config.env.Routing;
        this._maxNumViaLocations = routingConfig.MaxNumViaLocations;
    }

    public get maxNumViaLocations(): number {
        return this._maxNumViaLocations;
    }

    public get map() : L.Map {
        return this._map;
    }

    public set map(value: L.Map) {
        this._map = value;
    }

    public get mapCrs(): L.Proj.CRS {
        return this._mapCrs;
    }

    public set mapCrs(value: L.Proj.CRS) {
        this._mapCrs = value;
    }

    public get viewDate(): string {
        return this._viewDate;
    }

    public set viewDate(value: string) {
        this._viewDate = value;
	}
	
	public get routeColors(): string[] {
		return this._routeColors;
	}

    public getNextRouteColor(): string {

        // Reset the color count when array is "empty"
        if (this._routesInformation.length === 1){
            this._colorsCount = 0;
        }

        const ret = this._routeColors[this._colorsCount];

        if (this._colorsCount === 4) {
			this._colorsCount = 0;
        }
        else {
            this._colorsCount++;
        }

        return ret;
	}

    public get routesInformation(): IRouteInformation[] {
        return this._routesInformation;
    }

    public set routesInformation(value: IRouteInformation[]) {
        this._routesInformation = value;
	}

    public set routeInformation(value: IRouteInformation) {
        if (value == null) {
            if (this._routesInformation.length == 1) {
                this._routesInformation.splice(0, 1);
            }
            else {
                this._routesInformation = [];
            }
        } 
        else if (this._routesInformation.length > 0) {
            const index = this._routesInformation.findIndex(x => x.Id == value.Id);

            if (index === -1) {
                this._routesInformation.push(value);
            }
            else if (index === 0) {
                this._routesInformation[index] = value;
            }
            else {
                this._routesInformation.splice(index, 1);
                this._routesInformation.push(value);
            }
        }
        else {
            this._routesInformation.push(value);
        }
    }

    public removeRouteInformation(route: IRouteInformation): void {
        const index = this._routesInformation.findIndex(x => x.Id == route.Id);

        // If last route -> decrement route color index by one
        if (index === this._routesInformation.length-1) {
            if (this._colorsCount === 0) {
                this._colorsCount = 4;
            }
            else {
                this._colorsCount--;
            }
        }

        this._routesInformation.splice(index, 1);
    }

	/**
     * Function that needs to be overwridden by route implementation. Defaults to false.
     */
	public canAddLocations = (): boolean => {
		return false;
	}

	public canAddDynamicBlocks = (): boolean => {
		return false;
	}

	public canAddDynamicBlocksPolygon = (): boolean => {
		return false;
	}

    /**
     * Function that needs to be overwridden by route implementation. Defaults to false.
     */
    public canAddViaPoint = (): boolean => {
        return false;
    };

    public get featureTypes() : IFeatureTypeInfo[] {
        return this._featureTypes;
    }

    public setFeatureTypes(value: IFeatureTypeInfo[]): void {
        this._featureTypes = value;
    }

}






