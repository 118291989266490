export enum RouteEventItemType {
	ROUTE_SELECTED = 'ROUTE_SELECTED',
	ROUTE_CREATED = 'ROUTE_CREATED',
	ROUTE_COPY = 'ROUTE_COPY',
	ROUTE_CHECK_EMPTY = 'ROUTE_CHECK_EMPTY',
	CREATE_ROUTE_COPY = 'CREATE_ROUTE_COPY',
	CREATE_MAPSTATE_ROUTES = 'CREATE_MAPSTATE_ROUTES',
	MINIMIZE_ROUTES = 'MINIMIZE_ROUTES',
	MINIMIZE_ROUTE = 'MINIMIZE_ROUTE',
	MAXIMIZE_ROUTES  = 'MAXIMIZE_ROUTES',
}